<template>
    <BlockLayout title="addPhoto" @addItem="onAddItem">
        <input class="photos-block-input-file" type="file" ref="file" @change="onFileSelected" accept="image/png, image/gif, image/jpeg" />
        <div class="photos-block-content">
            <BlockItem v-for="photo in photos" :key="photo" @remove="onRemoveItem(photo)">
                <div class="row align-items-center">
                    <div class="col-auto">
                        <img :src="photo.getSrc()" class="photos-block-img" alt="image" />
                    </div>
                    <div class="col photos-block-item-text">
                        {{ photo.name }}
                    </div>
                </div>
            </BlockItem>
        </div>
    </BlockLayout>
</template>
<script>
import BlockLayout from './BlockLayout.vue';
import BlockItem from './BlockItem.vue';
import {fabric} from 'fabric';

export default {
    name: 'PhotosBlock',
    components: {
        BlockLayout,
        BlockItem,
    },
    data() {
        return {
            photos: [],
        };
    },
    methods: {
        onAddItem() {
            const file = this.$refs.file;
            file.click();
        },
        onRemoveItem(item) {
            const index = this.photos.indexOf(item);

            if (index < 0) {
                return;
            }

            this.photos.splice(index, 1);

            // Find object in fcanvas
            const found = window.gCanvas.getObjects().find((el) => el.id === item.id);
            
            if (found != null) {
                window.gCanvas.remove(found);
                window.gCanvas.fire('canvas:export', null);
            }
        },
        onFileSelected(e) {
            const canvas = window.gCanvas;
            let file = e.target.files.length > 0 ? e.target.files[0] : null;

            if (!file) {
                return;
            }

            // Add image to canvas
            const reader = new FileReader();

            reader.addEventListener('load', () => {
                const url = reader.result;
                fabric.Image.fromURL(url, (img) => {
                    img.name = file.name;
                    img.id = 'photo-' + this.photos.length;

                    // Resize the img
                    img.scaleToWidth(Math.min(img.width, canvas.getWidth()));

                    canvas.add(img);
                    canvas.centerObject(img);
                    canvas.calcOffset();
                    canvas.renderAll();
                    this.photos.push(img);

                    // export canvas
                    canvas.fire('canvas:export', null);
                });
            }, false);

            reader.readAsDataURL(file);

            e.target.value = '';
        }
    },
    mounted() {
        setTimeout(() => {
            window.gCanvas.getObjects().forEach(obj => {
                if (!obj.id || !obj.id.startsWith('photo-')) {
                    return;
                }

                this.photos.push(obj);
            });
        }, 500);
    }
}

</script>
<style lang="scss">
.photos-block-input-file {
    display: none;
}

.photos-block-img {
    display: block;
    width: 50px;
    height: 32px;
    object-fit: cover;
    object-fit: cover;
    object-position: center;
    background-color: white;
    border-radius: 7px;
}

.photos-block-item-text {
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>