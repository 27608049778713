<template>
    <BlockLayout title="addText" @addItem="onAddItem">
        <BlockItem v-for="(item, index) in items" :key="item" @remove="onRemoveItem(item)">
            <div class="row">
                <div class="col">
                    <textarea class="form-control text-block-textarea" placeholder="Введите текст" v-model="item.text" @input="onChangeItem(item)"></textarea>
                </div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col">
                    <select class="form-select" placeholder="Шрифт" v-model="item.font" @change="onChangeItem(item)">
                        <template v-for="font in fonts" :key="font">
                            <option :value="font">{{ font }}</option>
                        </template>
                    </select>
                </div>
            </div>
            <div class="row" style="margin-top: 10px; font-size: 14px;">
                <div class="col">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" value="" :id="'font-bold-' + index" v-model="item.bold" @change="onChangeItem(item)">
                        <label class="form-check-label" :for="'font-bold-' + index">
                            <b>Жирный</b>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" value="" :id="'font-italic-' + index" v-model="item.italic" @change="onChangeItem(item)">
                        <label class="form-check-label" :for="'font-italic-' + index">
                            <i>Курсив</i>
                        </label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" value="" :id="'font-shadow-' + index" v-model="item.shadow" @change="onChangeItem(item)">
                        <label class="form-check-label" :for="'font-shadow-' + index">
                            <span style="text-shadow: 1px 1px 1px black;">Тень</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 10px; font-size: 14px;">
                <div class="col-12">
                    <p class="text-muted">
                        Выравнивание:
                    </p>
                </div>
                <div class="col-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" :name="'tb-text-align-' + index" :id="'tb-text-align-left-' + index" v-model="item.align" value="left" @change="onChangeItem(item)">
                        <label class="form-check-label" :for="'tb-text-align-left-' + index">
                            Слева
                        </label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" :name="'tb-text-align-' + index" :id="'tb-text-align-center-' + index" v-model="item.align" value="center" @change="onChangeItem(item)">
                        <label class="form-check-label" :for="'tb-text-align-center-' + index">
                            Центр
                        </label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" :name="'tb-text-align-' + index" :id="'tb-text-align-right-' + index" v-model="item.align" value="right" @change="onChangeItem(item)">
                        <label class="form-check-label" :for="'tb-text-align-right-' + index">
                            Справа
                        </label>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 10px; font-size: 14px;">
                <div class="col">
                    <p>Размер текста:</p>
                    <div>
                        <input type="number" class="form-control form-control-sm" v-model="item.size" @change="onChangeItem(item)">
                    </div>
                </div>
                <div class="col">
                    <p>Цвет:</p>
                    <div>
                        <color-picker v-model:pureColor="item.color" @pureColorChange="onChangeItem(item)" />
                    </div>
                </div>
            </div>
        </BlockItem>
    </BlockLayout>
</template>
<script>
import BlockLayout from './BlockLayout.vue';
import BlockItem from './BlockItem.vue';
import fonts from '../../../config/fonts';
import { ColorPicker } from "vue3-colorpicker";
import {fabric} from 'fabric';
import "vue3-colorpicker/style.css";

export default {
    name: 'TextBlock',
    components: {
        BlockLayout,
        BlockItem,
        ColorPicker
    },
    data() {
        return {
            items: [],
            fonts: fonts,
        };
    },
    methods: {
        newItem(id) {
            return {
                text: 'Новый текст',
                font: 'Arial',
                bold: false,
                italic: false,
                shadow: false,
                align: 'left',
                size: 12,
                color: 'rgb(0, 0, 0)',
                id: 'text-' + id
            };
        },
        onAddItem() {
            const item = this.newItem(this.items.length);

            // Add text to canvas
            const canvas = window.gCanvas;

            if (!canvas) {
                return;
            }

            // Create text instance
            let text = new fabric.Text(item.text, {
                centeredScaling: false,
            });

            text.id = item.id;

            this.updateTextObject(text, item);

            // Add to canvas
            canvas.add(text);
            canvas.centerObject(text);
            canvas.calcOffset();
            canvas.renderAll();

            this.items.push(item);
            window.gCanvas.fire('canvas:export', null);
        },
        updateTextObject(obj, item) {
            obj.text = item.text,
            obj.fontFamily = item.font,
            obj.fontWeight = (item.bold ? 'bold' : 'normal');
            obj.fontStyle = (item.italic ? 'italic' : 'normal');
            obj.shadow = (item.shadow ? new fabric.Shadow({color: item.color, blur: 5}) : undefined);
            obj.fontSize = item.size;
            obj.set('fill', item.color);
            obj.set('textAlign', item.align);
        },
        onChangeItem(item) {
            const canvas = window.gCanvas;

            if (!canvas) {
                return;
            }

            const text = canvas.getObjects().find((el) => el.id === item.id);
        
            if (!text) {
                return;
            }
            console.log
            
            this.updateTextObject(text, item);

            canvas.renderAll();
            canvas.fire('canvas:export', null);
        },
        onRemoveItem(item) {
            const index = this.items.indexOf(item);

            if (index < 0) {
                return;
            }

            this.items.splice(index, 1);
            
            // Find object in fcanvas
            const found = window.gCanvas.getObjects().find((el) => el.id === item.id);
            
            if (found != null) {
                window.gCanvas.remove(found);
                window.gCanvas.fire('canvas:export', null);
            }
        }
    },
    mounted() {
        setTimeout(() => {
            window.gCanvas.getObjects().forEach(obj => {
                if (!obj.id || !obj.id.startsWith('text-')) {
                    return;
                }

                this.items.push({
                    text: obj.text,
                    font: obj.fontFamily,
                    bold: obj.fontWeight === 'bold',
                    italic: obj.fontStyle === 'italic',
                    shadow: Boolean(obj.shadow),
                    align: obj.textAlign,
                    size: obj.fontSize,
                    color: obj.get('fill'),
                    id: obj.id,
                });

                window.gCanvas.renderAll();
            });
        }, 500);
    }
}

</script>
<style lang="scss">
.text-block-textarea {
    max-height: 200px;
}
</style>