<template>
    <BlockLayout title="addDate" @addItem="onAddItem">
        <BlockItem v-for="(item, index) in items" :key="item" @remove="onRemoveItem(item)">
            <div class="row justify-content-end" style="margin-bottom: 20px;">
                <div class="col-auto">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" value="" :id="item.id + '-vertical'" v-model="item.vertical" @change="onChangeItem(items[index])">
                        <label class="form-check-label" :for="item.id + '-vertical'">
                            Вертикально
                        </label>
                    </div>
                </div>
            </div>
            <DateBlockItem v-model="item.day" title="Дата" @change="onChangeItem(items[index])" :min="1" :max="31" />
            <DateBlockItem v-model="item.month" title="Месяц" @change="onChangeItem(items[index])"  :min="1" :max="12" :offset="1" />
            <DateBlockItem v-model="item.year" title="Год" @change="onChangeItem(items[index])"  :min="1900" />
            <DateBlockItem v-model="item.hour" title="Час" @change="onChangeItem(items[index])"  :min="0" :max="59" />
            <DateBlockItem v-model="item.minute" title="Минута" @change="onChangeItem(items[index])"  :min="0" :max="59" />
        </BlockItem>
    </BlockLayout>
</template>
<script>
import BlockLayout from './BlockLayout.vue';
import BlockItem from './BlockItem.vue';
import {fabric} from 'fabric';
import locale from '../../../config/locale'
import DateBlockItem from './DateBlockItem.vue';

function dateText(s, pad) {
    pad = pad || 0;

    if (typeof s !== 'number') {
        return '';
    }

    return s.toString().padStart(pad, '0');
}

function dateIsVisible(s) {
    return s === true;
}

export default {
    name: 'DateBlock',
    components: {
        BlockLayout,
        BlockItem,
        DateBlockItem,
    },
    data() {
        return {
            items: [],
            months: locale.months.ru,
        };
    },
    methods: {
        onAddItem() {
            const item = this.newDateItem(this.items.length);

            this.items.push(item);

            const canvas = window.gCanvas;

            if (!canvas) {
                return;
            }

            const g = this.newDateGroup(item);

            canvas.add(g);
            canvas.centerObject(g);
            canvas.calcOffset();
            canvas.renderAll();
        },
        onChangeItem(item) {
            const canvas = window.gCanvas;

            if (!canvas) {
                return;
            }
            
            // find group
            const group = canvas.getObjects().find((el) => el.id === item.id);

            if (!group) {
                return;
            }

            this.updateDateGroup(group, item);
            canvas.centerObject(group);
            canvas.renderAll();
        },
        onRemoveItem(item) {
            const index = this.items.indexOf(item);

            if (index < 0) {
                return;
            }

            this.items.splice(index, 1);
            
            // Find object in fcanvas
            const found = window.gCanvas.getObjects().find((el) => el.id === item.id);
            
            if (found != null) {
                window.gCanvas.remove(found);
            }
        },
        newDateItem(id) {
            const today = new Date();
            return {
                day: {
                    value: today.getDate(),
                    enabled: true,
                    color: 'rgb(0, 0, 0)',
                },
                month: {
                    value: today.getMonth(),
                    enabled: true,
                    color: 'rgb(0, 0, 0)',
                },
                year: {
                    value: today.getFullYear(),
                    enabled: true,
                    color: 'rgb(0, 0, 0)',
                },
                hour: {
                    value: today.getHours(),
                    enabled: true,
                    color: 'rgb(0, 0, 0)',
                },
                minute: {
                    value: today.getMinutes(),
                    enabled: true,
                    color: 'rgb(0, 0, 0)',
                },
                vertical: false,
                id: 'date-' + id,
            };
        },
        newDateGroup(item) {
            let day = new fabric.Text('', {
                fontSize: 64,
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                hasBorders: true,
                id: item.id + '-day',
            });


            let div1 = new fabric.Rect({
                id: item.id + '-div1',
            });


            let month = new fabric.Text('', {
                fontFamily: 'sans-serif',
                fontSize: 20,
                fontWeight: 'bold',
                id: item.id + '-month',
                textAlign: 'center'
            });

            let year = new fabric.Text('', {
                fontFamily: 'sans-serif',
                fontSize: 40,
                fontWeight: 'bold',
                id: item.id + '-year',
            })

            let div2 = new fabric.Rect({
                id: item.id + '-div2',
            });

            let time = new fabric.Text('сағат', {
                fontFamily: 'sans-serif',
                fontSize: 20,
                fontWeight: 'bold',
                id: item.id + '-time',
            });

            let hm = new fabric.Text('', {
                fontFamily: 'sans-serif',
                fontSize: 40,
                fontWeight: 'bold',
                id: item.id + '-hm',
            });

            let group = new fabric.Group([
                day,
                div1,
                month,
                year,
                div2,
                time,
                hm,
            ]);

            group.id = item.id;

            this.updateDateGroup(group, item);

            return group;
        },
        updateDateGroup(group, item) {
            const objects = group.getObjects();
            let day = objects.find((el) => el.id === item.id + '-day');
            let div1 = objects.find((el) => el.id === item.id + '-div1');
            let month = objects.find((el) => el.id === item.id + '-month');
            let year = objects.find((el) => el.id === item.id + '-year');
            let div2 = objects.find((el) => el.id === item.id + '-div2');
            let time = objects.find((el) => el.id === item.id + '-time');
            let hm = objects.find((el) => el.id === item.id + '-hm');

            // update text
            day.set('text', dateText(item.day.value, 2));
            month.set('text', typeof item.month.value === 'number' ? this.months[item.month.value % 12] : '');
            year.set('text', dateText(item.year.value, 4));
            hm.set('text', dateText(item.hour.value, 2) + ':' + dateText(item.minute.value, 2));

            // update colors
            day.set('fill', item.day.color);
            div1.set('fill', item.day.color);
            month.set('fill', item.month.color);
            year.set('fill', item.year.color);
            div2.set('fill', item.year.color);
            time.set('fill', item.hour.color);
            hm.set('fill', item.hour.color);

            // update visibility
            day.set('visible', dateIsVisible(item.day.enabled));
            div1.set('visible', dateIsVisible(item.month.enabled) || dateIsVisible(item.year.enabled));
            month.set('visible', dateIsVisible(item.month.enabled));
            year.set('visible', dateIsVisible(item.year.enabled));
            div2.set('visible', dateIsVisible(item.hour.enabled) && dateIsVisible(item.minute.enabled));
            time.set('visible', dateIsVisible(item.hour.enabled) && dateIsVisible(item.minute.enabled));
            hm.set('visible', dateIsVisible(item.hour.enabled) && dateIsVisible(item.minute.enabled));

            // update orientation
            const div1Coord = {
                w: 3,
                h: 50,
                x: 80,
                y: 10, 
            };

            const monthCoord = {
                x: 100,
                y: 5
            };

            const yearCoord = {
                x: 100,
                y: 25,
                xv: -5,
                yv: 130,
            };

            const div2Coord = {
                w: 3,
                h: 50,
                x: 220,
                y: 10, 
                yv: 190, 
            };

            const timeCoord = {
                x: 250,
                y: 5,
                xv: 0,
                yv: 200,
            };

            const hmCoord = {
                x: 230,
                y: 25,
                xv: -10,
                yv: 230,
            };

            // set
            day.set('left', 0);
            day.set('top', 0);

            div1.set('width', item.vertical ? div1Coord.h : div1Coord.w);
            div1.set('height', item.vertical ? div1Coord.w : div1Coord.h);
            div1.set('top', item.vertical ? div1Coord.x : div1Coord.y);
            div1.set('left', item.vertical ? div1Coord.y : div1Coord.x);

            month.set('left', item.vertical ? monthCoord.y : monthCoord.x);
            month.set('top', item.vertical ? monthCoord.x : monthCoord.y);
            month.set('num', item.month.value);

            year.set('left', item.vertical ? yearCoord.xv : yearCoord.x);
            year.set('top', item.vertical ? yearCoord.yv : yearCoord.y);

            div2.set('width', item.vertical ? div2Coord.h : div2Coord.w);
            div2.set('height', item.vertical ? div2Coord.w : div2Coord.h);
            div2.set('top', item.vertical ? div2Coord.yv : div2Coord.y);
            div2.set('left', item.vertical ? div2Coord.y : div2Coord.x);

            time.set('left', item.vertical ? timeCoord.y : timeCoord.x);
            time.set('top', item.vertical ? timeCoord.yv : timeCoord.y);

            hm.set('left', item.vertical ? hmCoord.xv : hmCoord.x);
            hm.set('top', item.vertical ? hmCoord.yv : hmCoord.y);

            // update sizes
            if (!item.vertical) {
                month.set('width', year.get('width'));
            }

            group.remove(day);
            group.remove(div1);
            group.remove(month);
            group.remove(year);
            group.remove(div2);
            group.remove(time);
            group.remove(hm);

            group.addWithUpdate(day);
            group.addWithUpdate(div1);
            group.addWithUpdate(month);
            group.addWithUpdate(year);
            group.addWithUpdate(div2);
            group.addWithUpdate(time);
            group.addWithUpdate(hm);

        },
    },
    mounted() {
        setTimeout(() => {
            window.gCanvas.getObjects().forEach(obj => {
                if (!obj.id || !obj.id.startsWith('date-')) {
                    return;
                }

                const objects = obj.getObjects();
                let day = objects.find((el) => el.id === obj.id + '-day');
                let div1 = objects.find((el) => el.id === obj.id + '-div1');
                let month = objects.find((el) => el.id === obj.id + '-month');
                let year = objects.find((el) => el.id === obj.id + '-year');
                let hm = objects.find((el) => el.id === obj.id + '-hm');

                const hnm = hm.text.split(':');
                console.log();

                this.items.push({
                    day: {
                        value: Number(day.text),
                        enabled: Boolean(day.get('visible')),
                        color: day.get('fill'),
                    },
                    month: {
                        value: Number(month.num),
                        enabled: Boolean(month.get('visible')),
                        color: month.get('fill'),
                    },
                    year: {
                        value: Number(year.text),
                        enabled: Boolean(year.get('visible')),
                        color: year.get('fill'),
                    },
                    hour: {
                        value: Number(hnm[0]),
                        enabled: Boolean(hm.get('visible')),
                        color: hm.get('fill'),
                    },
                    minute: {
                        value: Number(hnm[1]),
                        enabled: Boolean(hm.get('visible')),
                        color: hm.get('fill'),
                    },
                    vertical: div1.get('width') > div1.get('height'),
                    id: obj.id,
                });
            });
        }, 500);
    }
}

</script>
