<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        
            <div class="collapse navbar-collapse main-menu" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <template v-for="item in menu" :key="item">
                        <template v-if="item.items">
                            <li class="nav-item">
                                <a href="javascript:void(0);" class="nav-link" @click="onSelectItem(item.items)">{{ item.title[$root.getLang()] }}</a>
                            </li>
                        </template>
                        <template v-else>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="javascript:void(0);" data-bs-toggle="dropdown">{{ item.title[$root.getLang()] }}</a>
                                <ul class="dropdown-menu">
                                    <template v-for="m in item.children" :key="m">
                                        <MenuItem :menu="m" @selectItem="onSelectItem" />
                                    </template>
                                </ul>
                            </li>
                        </template>
                    </template>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import MenuItem from './MenuItem.vue';

export default {
  name: 'MainMenu',
  components: {
    MenuItem,
  },
  props: {
    menu: {
        type: Array,
        default: () => []
    }
  },
  emits: [
    'selectItem'
  ],
  methods: {
    onSelectItem(items) {
        this.$emit('selectItem', items);
    }
  }
}
</script>

<style lang="scss">
    .main-menu {
        $bg-color: #08b16c;
        $bg-color-hover: #0f784f;
        $border-radius: 5px;

        background-color: $bg-color;
        border-radius: $border-radius;

        a.nav-link {
            color: white;
        }

        ul.dropdown-menu {
            background-color: $bg-color;
            border-radius: $border-radius;

            a.dropdown-item {
                color: white;

                &:hover {
                    background-color: $bg-color-hover !important;
                }
            }
        }
    }
</style>