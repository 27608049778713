export default [
    {
        title: 'Еске салу',
        stickers: [
            {
                name: 'Рамка',
                url: '/stickers/frame1.png'
            },
            {
                name: 'Облоко',
                url: '/stickers/obloko1.png'
            },
            {
                name: 'Полумесяц',
                url: '/stickers/polumes.png'
            }
        ]
    },
    {
        title: 'Instagram',
        stickers: [
            {
                name: 'Водяной знак',
                url: '/stickers/allart_v.png'
            }
        ]
    },
    {
        title: 'Тойға шақыру',
        stickers: [
            {
                name: 'Какой-то стикер',
                url: '/stickers/dev.png'
            }
        ]
    },
    {
        title: 'Алғыс хат',
        stickers: [
            {
                name: 'Какой-то стикер',
                url: '/stickers/polumes.png'
            }
        ]
    }
]
