export default {
    availableLocales: [
        {
            name: 'Russian',
            selfName: 'Русский',
            code: 'ru'
        },
        {
            name: 'Kazakh',
            selfName: 'Қазақша',
            code: 'kk'
        },
        {
            name: 'English',
            selfName: 'English',
            code: 'en'
        }
    ],

    months: {
        ru: [
            'қаңтар',
            'ақпан',
            'наурыз',
            'сәуір',
            'мамыр',
            'маусым',
            'шілде',
            'тамыз',
            'қыркүйек',
            'қазан',
            'қараша',
            'желтоқсан',
        ],
        kk: [
            'January',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december',
        ],
        en: [
            'қаңтар',
            'ақпан',
            'наурыз',
            'сәуір',
            'мамыр',
            'маусым',
            'шілде',
            'тамыз',
            'қыркүйек',
            'қазан',
            'қараша',
            'желтоқсан',
        ],
    },

    ui: {
        'addPhoto': {
            'ru': 'Добавить фото',
            'kk': 'Фотосурет қосыңыз',
            'en': 'Add a photo',
        },
        'addText': {
            'ru': 'Добавить текст',
            'kk': 'Мәтін қосу',
            'en': 'Add Text',
        },
        'addDate': {
            'ru': 'Добавить дату',
            'kk': 'Қосу күні',
            'en': 'Add Date',
        },
        'addSticker': {
            'ru': 'Добавить стикер',
            'kk': 'Стикер қосыңыз',
            'en': 'Add a sticker',
        }
    }
}
