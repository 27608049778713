<template>
    <BlockLayout title="addSticker" @addItem="onAddItem">
        <BlockItem v-for="(item) in items" :key="item" @remove="onRemoveItem(item)">
            <div class="row">
                <div class="col">
                    <p class="small text-muted">
                        Выберите тематику:
                    </p>
                    <select class="form-select form-select-sm" v-model="currentCategory">
                        <option v-for="(cat, index) in categories" :key="cat" :value="index" :class="{'sticker-category-active': index === item.category}">{{ cat }}</option>
                    </select>
                </div>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col">
                    <carousel :items-to-show="2">
                        <slide v-for="(sticker, index) in stickers[currentCategory].stickers" :key="sticker">
                            <a href="javascript:void(0)" class="sticker-slide" :class="{'sticker-active': item.category === currentCategory && index === item.image}" @click="onSelectSticker(item, currentCategory, index)">
                                <img :src="sticker.url" :alt="sticker.title" />
                            </a>
                        </slide>
                        <template #addons>
                            <navigation />
                            <pagination />
                        </template>
                    </carousel>
                </div>
            </div>
        </BlockItem>
    </BlockLayout>
</template>
<script>
import BlockLayout from './BlockLayout.vue';
import BlockItem from './BlockItem.vue';
import stickers from '../../../config/stickers';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import {fabric} from 'fabric';

export default {
    name: 'StickerBlock',
    components: {
        BlockLayout,
        BlockItem,
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            items: [],
            stickers: stickers,
            currentCategory: 0,
        };
    },
    computed: {
        categories() {
            return this.stickers.map(s => s.title);
        }
    },
    methods: {
        onAddItem() {
            const item = this.newStickerItem(this.items.length);

            this.loadNewImageToCanvas(item).then(() => {
                this.items.push(item);
                window.gCanvas.fire('canvas:export', null);
            });
        },
        loadNewImageToCanvas(item) {
            const stickers = this.stickers;
            const canvas = window.gCanvas;

            return new Promise((resolve) => {
                fabric.Image.fromURL(stickers[item.category].stickers[item.image].url, (img) => {
                    img.id = item.id;
                    img.scaleToWidth(200);
                    img.set('num', item.image);
                    img.set('category', item.category);

                    canvas.add(img);
                    canvas.centerObject(img);
                    canvas.calcOffset();
                    canvas.renderAll();

                    resolve(img);
                });
            });
        },
        onChangeItem(item) {
            const index = this.items.indexOf(item);

            if (index < 0) {
                return;
            }
            
            // Find object in fcanvas
            const found = window.gCanvas.getObjects().find((el) => el.id === item.id);

            // Remove old image
            if (found != null) {
                window.gCanvas.remove(found);
            }

            // Add new
            this.loadNewImageToCanvas(item).then(() => {
                window.gCanvas.fire('canvas:export', null);
            });
        },
        onSelectSticker(item, category, img) {
            item.category = category;
            item.image = img;
            this.onChangeItem(item);
        },
        onRemoveItem(item) {
            const index = this.items.indexOf(item);

            if (index < 0) {
                return;
            }

            this.items.splice(index, 1);
            
            // Find object in fcanvas
            const found = window.gCanvas.getObjects().find((el) => el.id === item.id);
            
            if (found != null) {
                window.gCanvas.remove(found);
                window.gCanvas.fire('canvas:export', null);
            }
        },
        newStickerItem(id) {
            return {
                id: 'sticker-' + id,
                category: 0,
                image: 0
            };
        }
    },
    mounted() {
        setTimeout(() => {
            window.gCanvas.getObjects().forEach(obj => {
                if (!obj.id || !obj.id.startsWith('sticker-')) {
                    return;
                }

                this.items.push({
                    id: obj.id,
                    category: Number(obj.category),
                    image: Number(obj.num),
                });
            });
        }, 500);
    }
}
</script>
<style lang="scss">
    .sticker-slide {
        border: 2px solid #ccc;

        & > img {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }

        &.sticker-active {
            border-color: #08b16c;
        }
    }
    .sticker-category-active {
        font-weight: bold;
    }
</style>