<template>
    <template v-if="menu.items">
        <li>
            <a class="dropdown-item" href="javascript:void(0);" @click="onSelectItem(menu.items)">{{ menu.title[getLang()] }}</a>
        </li>
    </template>
    <template v-else>
        <li>
            <a href="javascript:void(0);" class="dropdown-item">
                {{ menu.title[getLang()] }} &raquo;
            </a>
            <ul class="dropdown-menu dropdown-submenu">
                <template v-for="item in menu.children" :key="item">
                    <MenuItem :menu="item" @select-item="onSelectItem" />
                </template>
            </ul>
        </li>
    </template>
</template>

<script>
export default {
    name: 'MenuItem',
    props: {
        menu: {
            type: Object,
            required: true
        },
    },
    emits: ['selectItem'],
    methods: {
        onSelectItem(items) {
            this.$emit('selectItem', items);
        },
        getLang() {
            return this.$root.getLang();
        }
    }
}
</script>

<style>
    .dropdown-menu li {
        position: relative;
    }
    .dropdown-menu .dropdown-submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: -7px;
    }
    .dropdown-menu .dropdown-submenu-left {
        right: 100%;
        left: auto;
    }
    .dropdown-menu > li:hover > .dropdown-submenu {
        display: block;
    }
</style>